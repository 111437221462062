import React, { Component } from "react";
import "./datatables.scss"
//Import Breadcrumb
import RCDisplayPage from "../../components/Common/RCDisplayPage";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store

import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_GetReport,
  Fn_AddEditData,
  Fn_ChangeStateValue,
  Fn_ExportExcel,
} from "../../store/functions";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  Alert,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  AvForm,
  AvField,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";

import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./datatables.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
function getLastYearDate() {
  var today = new Date();
  var lastYear = today.getFullYear() - 1;
  var lastYearDate = new Date(lastYear, today.getMonth(), today.getDate());
  var formattedDate = lastYearDate.toISOString().split("T")[0]; // Extracting yyyy-MM-dd from ISO string
  return formattedDate;
}
class pageAddEdit_PersonToPerson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridData: [],
      formData: {},
      totalAmount: 0,
      productData: [],
      companies: [],
      products: [],
      PayeeInfo: [],
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Person to person";

    //Event Binding

    this.handleGINChange = this.handleGINChange.bind(this);

    this.btnSave_onClick = this.btnSave_onClick.bind(this);
    this.handleFormChange  = this.handleFormChange.bind(this);
  }

  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));

    if (obj) {
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          Dgin: obj.name || "",
          Dname: obj.username || "",
        },
      }));
    }
    setTimeout(() => {
      console.log(this.state.formData);
    }, 10000);
  }
  componentDidUpdate(prevProps, prevState) {
    // Check if PayeeInfo has changed
    if (this.state.PayeeInfo !== prevState.PayeeInfo) {
      console.log(this.state.PayeeInfo);
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          ["Pname"]: this.state.PayeeInfo[0].Name,
        },
      }));
    }
  }

  handleFormChange(event) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }
  handleGINChange(event) {
    const { name, value } = event.target;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
    this.GetPayeeInfo(value);
  }
  GetPayeeInfo = value => {
    Fn_FillListData(
      this.obj,
      "PayeeInfo",
      API_WEB_URLS.MASTER +
        "/0/token/GetBalance/1/Mem/Balance?ListFor=" +
        value +
        "&F_AccountType=11"
    );
  };
  btnSave_onClick(event, formData) {
    let vformData = new FormData();
    vformData.append("Name", formData.Name);
    Fn_AddEditData(
      this.obj,
      { arguList: { id: 0, formData: vformData } },
      "Product/InsertProduct",
      "#",
      true,
      "Id"
    );
  }

  render() {
    return (
      <>
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumbs
                title={this.breadCrumbTitle}
                breadcrumbItem={this.breadCrumbItem}
              />
              <Row>
                <Col lg="14">
                  <Card>
                    <CardBody>
                      <div className="wizard clearfix">
                        <div className="content clearfix">
                          <AvForm
                            className="needs-validation"
                            onValidSubmit={this.btnSave_onClick}
                          >
                            <Row>
                              <Col lg="12">
                                <Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="Name"
                                        className="col-form-label"
                                      >
                                        Depositor GIN
                                      </label>
                                    </Col>
                                    <Col sm="4" className="mb-3">
                                      <input
                                        name="Dgin"
                                        label=""
                                        disabled
                                        value={
                                          this.state.formData.Dgin == undefined
                                            ? ""
                                            : this.state.formData.Dgin
                                        }
                                        onChange={this.handleFormChange}
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="Name"
                                        className="col-form-label"
                                      >
                                        Depositor Name
                                      </label>
                                    </Col>
                                    <Col sm="4" className="mb-3">
                                      <input
                                        name="Dname"
                                        label=""
                                        disabled
                                        value={
                                          this.state.formData.Dname == undefined
                                            ? ""
                                            : this.state.formData.Dname
                                        }
                                        onChange={this.handleFormChange}
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="Name"
                                        className="col-form-label"
                                      >
                                        Payee GIN
                                      </label>
                                    </Col>
                                    <Col sm="4" className="mb-3">
                                      <input
                                        name="Pgin"
                                        label=""
                                        value={
                                          this.state.formData.Pgin == undefined
                                            ? ""
                                            : this.state.formData.Pgin
                                        }
                                        onChange={this.handleGINChange}
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="Pname"
                                        className="col-form-label"
                                      >
                                        Payee Name
                                      </label>
                                    </Col>
                                    <Col sm="4" className="mb-3">
                                      <input
                                        name="Pname"
                                        label=""
                                        disabled
                                        value={
                                          this.state.formData.Pname == undefined
                                            ? ""
                                            : this.state.formData.Pname
                                        }
                                        onChange={this.handleFormChange}
                                        type="text"
                                        className="form-control"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm="2" className="mb-3">
                                      <label
                                        htmlFor="Amount"
                                        className="col-form-label "
                                      >
                                        Amount
                                      </label>
                                    </Col>
                                    <Col sm="4" className="mb-3">
                                      <input
                                        label=""
                                        name="Amount"
                                        value={
                                          this.state.formData.Amount ==
                                          undefined
                                            ? ""
                                            : this.state.formData.Amount
                                        }
                                        onChange={this.handleFormChange}
                                        type="number"
                                        className="form-control no-spinners"
                                      />
                                    </Col>
                                  </Row>
                                  <Col sm="3" className="mb-3">
                                    <Button
                                      type="submit"
                                      color="success"
                                      className="mr-1 waves-effect waves-light"
                                    >
                                      Add
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </AvForm>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      </>
    );
  }
}
export default compose(container)(pageAddEdit_PersonToPerson);
